import { get } from "svelte/store";
import { mounted } from "$lib/stores";
import { redirect } from "@sveltejs/kit";
import { ressources } from "$lib/stores";

export const load = async ({ url, params }) => {
  if (url.pathname.toString().includes("app_print")) {
    return;
  }
  if (get(mounted) === false && url.pathname !== "/") {
    redirect(302, "/?redirect=" + (url.pathname ?? "/"));
  }
  if (
    params.ressourceId &&
    !get(ressources)
      .map((r) => r.id)
      .includes(params.ressourceId)
  ) {
    redirect(302, "/workspaces");
  }
};
