import { browser, building, dev, version } from "$app/environment";

const API_HOST = dev
  ? "http://localhost:3000"
  : "https://backendbbia.martinrougeron.me";

const API_URL = `${API_HOST}/api/v1/`;

const defaultTimeLine: number[] = [
  300, // 5 minutes - Immediate visibility; mission-critical systems down, severe impact starts.
  900, // 15 minutes - Critical response needed; impact highly visible and escalating.
  3600, // 1 hour - Major business functions affected; public and internal visibility high.
  7200, // 2 hours - High-priority systems must be recovered to avoid significant operational impact.
  14400, // 4 hours - Prolonged downtime; customer and stakeholder confidence affected.
  28800, // 8 hours - Full business day approaching; severe impact on operations and revenue.
  43200, // 12 hours - Extended downtime; strategic decisions may be required to mitigate loss.
  86400, // 24 hours - Next business day; critical functions must be restored to prevent long-term damage.
  172800, // 2 days - Continued downtime affecting business continuity, regulatory concerns may arise.
  259200, // 3 days - Significant business disruption; potential long-term consequences.
  604800, // 7 days - Deferred recovery; extended downtime could lead to lasting reputational and financial damage.
  1209600, // 14 days - Extended recovery; significant impact on business operations and reputation.
  2592000, // 30 days - Long-term recovery; significant impact on business continuity and financial health.
];

export { API_HOST, API_URL, defaultTimeLine };
