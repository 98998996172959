import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/about": [17],
		"/billing": [18],
		"/contact": [19],
		"/impacts": [20],
		"/impacts/[id]": [21],
		"/login": [22],
		"/login/google": [23],
		"/login/google/callback": [24],
		"/logout": [25],
		"/me": [26],
		"/privacy": [27],
		"/terms": [28],
		"/workspaces": [29],
		"/[ressourceId]": [4,[2]],
		"/[ressourceId]/activities": [5,[2]],
		"/[ressourceId]/activities/create": [7,[2]],
		"/[ressourceId]/activities/[id]": [6,[2]],
		"/[ressourceId]/app_print/[token]": [8,[2]],
		"/[ressourceId]/dashboard": [9,[2]],
		"/[ressourceId]/dashprint": [10,[2]],
		"/[ressourceId]/services": [11,[2]],
		"/[ressourceId]/services/[id]": [12,[2]],
		"/[ressourceId]/teams": [13,[2]],
		"/[ressourceId]/teams/[id]": [14,[2]],
		"/[ressourceId]/vendors": [15,[2]],
		"/[ressourceId]/vendors/[id]": [16,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';